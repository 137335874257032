.notFound {
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 12px;
    h2 {
        font-size: 24px;
    }

    h2,
    p {
        margin-bottom: 0;
    }
}
